export default {
  data() {
    return {
      dataSelectDialog: false,
      dataList: [],
      formType: "st-shop-goods",
      extraForm: {
        pay_type: 0,
        data_type: 1 // 不要微信小商店商品
      }
    }
  },
  methods: {
    // 点击选择数据
    dataChange(val) {
      this.dataList = val
    },
  }
}