var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('list-layout',{ref:"distributionList",attrs:{"filter-form":_vm.filterForm,"thead":_vm.thead,"tab-arr":_vm.tabArr,"on-fetch":_vm.getList,"on-edit":_vm.onEdit},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"top-btn"},[_c('el-button',{attrs:{"size":"small","type":"primary"},on:{"click":_vm.addNew}},[_vm._v("新增")])],1)]},proxy:true},{key:"filter",fn:function(){return [_c('list-filter',{attrs:{"export-api":_vm.exportRetailGoods,"filterForm":_vm.filterForm,"uploadFilter":_vm.ok}})]},proxy:true},{key:"tabletop",fn:function(ref){
var idList = ref.idList;
return [_c('div',{staticClass:"list-btn"},[_c('el-button',{attrs:{"size":"small","type":"danger"},on:{"click":function($event){return _vm.handleMultiDel(idList)}}},[_vm._v("删除")])],1)]}},{key:"item_is_show",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.is_show ? '显示' : '隐藏'))]}},{key:"item_cover",fn:function(ref){
var row = ref.row;
return [_c('list-image',{attrs:{"src":row.cover,"height":150,"is-image":""}})]}},{key:"item_name",fn:function(ref){
var row = ref.row;
return [_c('p',{staticStyle:{"white-space":"pre-wrap"}},[_vm._v(_vm._s(row.name))])]}},{key:"item_image",fn:function(ref){
var row = ref.row;
return [(row.res[0])?_c('list-image',{attrs:{"fit":"cover","width":80,"src":row.res[0].thumbnail || row.res[0].url}}):_c('list-image',{attrs:{"fit":"cover","width":80,"src":""}})]}},{key:"item_status_text",fn:function(ref){
var row = ref.row;
return [_c('ListStatusText',{attrs:{"text":row.status_text,"type":row.status_text === '销售中'
            ? 'success'
            : row.status_text === '待上架'
            ? 'warning'
            : 'info'}})]}},{key:"item_price",fn:function(ref){
            var row = ref.row;
return [_vm._v(" "+_vm._s(row.price || '')+" "+_vm._s(row.price && row.integral ? '+' : '')+" "+_vm._s(row.integral ? row.integral + '积分' : '')+" ")]}},{key:"operation",fn:function(scope){return [_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.onDelete([scope.row.id])}}},[_vm._v(_vm._s('删除'))])]}}])}),_c('data-selector',{attrs:{"selected":_vm.dataList,"dataType":_vm.formType,"form":_vm.extraForm},on:{"change":_vm.dataChange},model:{value:(_vm.dataSelectDialog),callback:function ($$v) {_vm.dataSelectDialog=$$v},expression:"dataSelectDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }