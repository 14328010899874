<template>
  <div>
    <!--
    1.添加为分销的商品可参与分销

    2.点击【新增】打开弹窗，可选择人民币类型的商品（不支持积分商品）
    -->
    <list-layout
      ref="distributionList"
      :filter-form="filterForm"
      :thead="thead"
      :tab-arr="tabArr"
      :on-fetch="getList"
      :on-edit="onEdit"
    >
      <template #top>
        <div class="top-btn">
          <el-button size="small" @click="addNew" type="primary"
            >新增</el-button
          >
        </div>
      </template>
      <template #filter>
        <list-filter
          :export-api="exportRetailGoods"
          :filterForm="filterForm"
          :uploadFilter="ok"
        />
      </template>
      <template #tabletop="{ idList }">
        <div class="list-btn">
          <el-button size="small" type="danger" @click="handleMultiDel(idList)"
            >删除</el-button
          >
        </div>
      </template>
      <template #item_is_show="{ row }">{{
        row.is_show ? '显示' : '隐藏'
      }}</template>
      <template #item_cover="{ row }">
        <list-image :src="row.cover" :height="150" is-image />
      </template>
      <template #item_name="{ row }">
        <p class="" style="white-space: pre-wrap;">{{row.name}}</p>
      </template>
      <template slot="item_image" slot-scope="{ row }">
        <list-image
          v-if="row.res[0]"
          fit="cover"
          :width="80"
          :src="row.res[0].thumbnail || row.res[0].url"
        />
        <list-image v-else fit="cover" :width="80" src="" />
      </template>
      <template slot="item_status_text" slot-scope="{ row }">
        <ListStatusText
          :text="row.status_text"
          :type="
            row.status_text === '销售中'
              ? 'success'
              : row.status_text === '待上架'
              ? 'warning'
              : 'info'
          "
        ></ListStatusText>
      </template>
      <template #item_price="{ row }">
        {{ row.price || '' }} {{ row.price && row.integral ? '+' : '' }}
        {{ row.integral ? row.integral + '积分' : '' }}
      </template>
      <template v-slot:operation="scope">
        <el-button type="text" size="small" @click="onDelete([scope.row.id])">{{
          '删除'
        }}</el-button>
      </template>
    </list-layout>

    <data-selector
      v-model="dataSelectDialog"
      :selected="dataList"
      :dataType="formType"
      @change="dataChange"
      :form="extraForm"
    />
  </div>
</template>

<script>
import ListLayout from '@/base/layout/ListLayout'
import ListImage from '@/base/components/List/ListImage'
import {addDistribution, getRetailGoods, removeDetailGood} from '../api/list'
import ListFilter from '../../shop/components/ShopList/ListFilter.vue'
import DataSelector from '../../app-design/components/Normal/dataSelector.vue'
import dataSelector from '../mixin/dataSelector'
import ListStatusText from '../../../base/components/List/ListStatusText'
import { exportRetailGoods } from '../api/list'

export default {
  components: {
    ListStatusText,
    ListFilter,
    ListImage,
    ListLayout,
    DataSelector,
  },
  mixins: [dataSelector],
  data() {
    return {
      tabArr: [
        // { label: "全部", name: "normal" },
        // { label: "销售中", name: "sale" },
        // { label: "待上架", name: "waite_sale" },
        // { label: "已下架", name: "no_sale" },
        // { label: "回收站", name: "deleted" },
      ],
      filterForm: {
        tab: 'normal',
        name: '', // 商品名
        status: -1, // 商品状态
        order_by: '', // 排序字段
        is_desc: 1, // 正序还是倒序
        min_price: '', // 最低价
        max_price: '', // 最高价
        category_id: '', // 分类id
        start_time: -1, //开始时间
        end_time: -1, //结束时间
        page_size: 15, //每页多少条数据
      },
      thead: [
        { type: 'selection' },
        {
          label: '商品图片',
          prop: 'image',
          minWidth: 140,
          type: 'slot',
          tooltip: false,
        },
        { label: '商品名称', prop: 'name', minWidth: 200, type: 'slot', tooltip: false },
        { label: '支付类型', prop: 'pay_type_text', minWidth: 120 },
        {
          label: '商品分类',
          prop: 'category_name',
          minWidth: 120,
        },
        {
          label: '价格',
          prop: 'price',
          minWidth: 120,
          sortable: true,
          type: 'slot',
        },
        {
          label: '库存',
          prop: 'stocks',
          minWidth: 120,
        },
        {
          label: '销量',
          prop: 'orders_count',
          minWidth: 120,
        },
        {
          label: '商品状态',
          prop: 'status_text',
          minWidth: 200,
          type: 'slot',
        },
        // {
        //   label: '数据类型',
        //   prop: 'data_text',
        //   minWidth: 200,
        // },
        {
          label: '创建时间',
          prop: 'create_time',
          minWidth: 200,
          sortable: true,
        },
        { label: '操作', type: 'operation', width: 80, align: 'right' },
      ],
    }
  },
  watch: {
    dataSelectDialog(newValue, oldValue) {
      if (!newValue && this.dataList.length) {
        // console.log(this.dataList) // [{id, name}]
        Promise.all(
          this.dataList.map(
            (dl) =>
              new Promise((resolve) => {
                addDistribution({
                  id: dl.id,
                })
                  .then(({ code }) => {
                    if (code) {
                      this.$message.success(`分销商品【${dl.name}】添加成功！`)
                    } else {
                      this.$message.error(`分销商品【${dl.name}】添加失败！`)
                    }
                  })
                  .finally(() => {
                    resolve()
                  })
              })
          )
        )
          .then(() => {
            this.ok({})
          })
          .catch(() => {})
      }
    },
  },
  methods: {
    onDelete(arr = []) {
      this.handleMultiDel(arr)
    },
    handleMultiDel(arr = []) {
      if (arr.length > 1) {
        this.$msgbox
          .confirm('确定要删除选中的商品数据吗？', '提示', {
            type: 'info',
          })
          .then((res) => {
            Promise.all(arr.map(ar => new Promise(resolve => {
              removeDetailGood({ id: ar }).then(({data, msg}) => {
                resolve()
              }).catch(() => {
                resolve()
              })
            }))).then(() => {
              this.ok({})
            }).finally(() => {
              this.$message.info('批量操作完成')
            })
          })
          .catch((err) => {})
      } else if (arr.length) {
        this.$msgbox
            .confirm('确定要删除这条商品数据吗？', '提示', {
              type: 'info',
            })
            .then((res) => {
              Promise.all(arr.map(ar => new Promise(resolve => {
                removeDetailGood({ id: ar }).then(({data, msg}) => {
                  resolve()
                }).catch(() => {
                  resolve()
                })
              }))).then(() => {
                this.ok({})
              }).finally(() => {
              })
            })
            .catch((err) => {})
      } else {
        this.$message.info('请选择数据项')
      }
    },
    exportRetailGoods,
    addNew() {
      this.dataList = []
      this.dataSelectDialog = true
    },
    onEdit(row) {},
    getList(q) {
      return getRetailGoods(q)
    },
    // 筛选回调
    ok(e) {
      const pageData = { ...this.filterForm, ...e, page: 1 }
      this.$refs.distributionList.getList(pageData)
    },
  },
}
</script>

<style scoped></style>
