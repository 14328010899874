import api from '@/base/utils/request'
import fetchFile from '@/base/utils/download'

export const getRetailGoods = (data) => {
  return api({ url: '/admin/retail/goods/index', method: 'post', data })
}

export const addDistribution = (data) => {
  return api({
    url: '/admin/retail/goods/addGoods',
    method: 'post',
    data,
    notCancel: true,
  })
}

export const exportRetailGoods = (data) => {
  return fetchFile({
    url: '/admin/retail/goods/exportIndex',
    method: 'post',
    data,
    download: true,
  })
}

export const removeDetailGood = (data) => {
  return api({
    url: '/admin/retail/goods/delete',
    method: 'post',
    data,
    notCancel: true,
  })
}
